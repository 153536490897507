import React from 'react';
import '../components/dash/styles/First.css';

const TermsOfUse = () => {
  return (
    <section className="terms-of-use">
      <div className="container">
        <h1 className="about-title">شروط الاستخدام</h1>
        <p>
          مرحبًا بكم في موقع "حقيبتي". باستخدامك لهذا الموقع، فإنك توافق على الالتزام بشروط
          الاستخدام التالية. يرجى قراءة هذه الشروط بعناية قبل استخدام الموقع.
        </p>

        <h2 className="about-title1">1. قبول الشروط</h2>
        <p>
          باستخدامك لهذا الموقع، فإنك توافق على شروط الاستخدام هذه وجميع القوانين واللوائح
          المطبقة. إذا كنت لا توافق على أي من هذه الشروط، يرجى عدم استخدام الموقع.
        </p>

        <h2 className="about-title1">2. تغييرات على الشروط</h2>
        <p>
          قد نقوم بتحديث شروط الاستخدام هذه من وقت لآخر. تعتبر التعديلات سارية فور نشرها على
          الموقع. يجب عليك مراجعة هذه الشروط بشكل دوري للبقاء على اطلاع بأي تغييرات.
        </p>

        <h2 className="about-title1">3. استخدام المحتوى</h2>
        <p>
          جميع المحتويات المقدمة على هذا الموقع هي لأغراض تعليمية ومهنية فقط. لا يجوز لك استخدام
          المحتوى لأغراض تجارية دون إذن مسبق من موقعنا.
        </p>

        <h2 className="about-title1">4. حقوق الملكية الفكرية</h2>
        <p>
          جميع حقوق الملكية الفكرية في الموقع، بما في ذلك حقوق التأليف والنشر والعلامات التجارية،
          هي ملك لموقع "حقيبتي" أو مرخصة له. لا يجوز لك نسخ أو توزيع أو تعديل أي من هذه الحقوق
          دون إذن مسبق.
        </p>

        <h2 className="about-title1">5. سياسة الخصوصية</h2>
        <p>
          للمزيد من المعلومات حول كيفية جمع واستخدام المعلومات الشخصية الخاصة بك، يرجى الاطلاع
          على سياسة الخصوصية الخاصة بنا.
        </p>

        <h2 className="about-title1">6. المسؤولية</h2>
        <p>
          لا يتحمل موقع "حقيبتي" أي مسؤولية عن أي أضرار قد تحدث نتيجة لاستخدامك للموقع. يتم توفير
          المحتوى والخدمات على الموقع كما هي دون أي ضمانات.
        </p>

        <h2 className="about-title1">7. إنهاء الاستخدام</h2>
        <p>
          يمكننا إنهاء أو تعليق وصولك إلى الموقع في أي وقت ودون إشعار إذا انتهاكت شروط الاستخدام
          هذه.
        </p>

        <h2 className="about-title1">8. القانون المعمول به</h2>
        <p>
          تخضع شروط الاستخدام هذه للقوانين المعمول بها في الدولة التي يقع فيها موقع "حقيبتي".
        </p>

        <p>
          إذا كان لديك أي أسئلة حول شروط الاستخدام هذه، يرجى الاتصال بنا عبر{' '}
          <a href="contact.php" className="contact-link">صفحة الاتصال</a>.
        </p>
      </div>
    </section>
  );
};

export default TermsOfUse;
