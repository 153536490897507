import React, { useState, useEffect } from "react";
import Image from "../assets/image.png";
import Logo from "../assets/logo.png";
import GoogleSvg from "../assets/icons8-google.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Rgister.css"; // If using SCSS
import { registerUser } from "../sevices/apiService"; // If using TypeScript
import CryptoJS from "crypto-js"; // استيراد مكتبة CryptoJS
import { toast } from "react-toastify";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [gender, setGender] = useState(""); // State to store selected gender
  const navigate = useNavigate();

  // التحقق من وجود المعرف المشفر في LocalStorage
  useEffect(() => {
    const checkEncryptedId = () => {
      const encryptedId = localStorage.getItem("id");

      if (encryptedId) {
        try {
          const bytes = CryptoJS.AES.decrypt(encryptedId, "your-secret-key");
          const decryptedId = bytes.toString(CryptoJS.enc.Utf8);

          if (decryptedId) {
            navigate("/dashboard"); // توجيه المستخدم إلى لوحة التحكم إذا كان معرف المستخدم موجودًا
          }
        } catch (error) {
          console.error("Decryption error:", error);
          toast.error("خطأ في فك التشفير");
        }
      }
    };

    checkEncryptedId();
  }, [navigate]);

  // التعامل مع التسجيل
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const lastname = e.target.lastname.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (name && lastname && email && gender && password && confirmPassword) {
      if (password === confirmPassword) {
        const formData = {
          username: name + " " + lastname,
          email,
          gender, // Include gender in form data
          password,
        };
        try {
          const result = await registerUser(formData); // استدعاء دالة التسجيل

          if (result.statut === "success") {
            toast.success("تم إنشاء حساب بنجاح");
            setTimeout(() => {
              navigate("/login"); // توجيه المستخدم إلى صفحة تسجيل الدخول بعد نصف ثانية
            }, 500);
          } else if (result.message === "exist") {
            toast.error("لقد تم إنشاء حساب بهذا البريد الإلكتروني من قبل");
          } else {
            toast.error(result.message); // عرض أي رسالة خطأ أخرى
          }
        } catch (err) {
          toast.error("حدث خطأ أثناء التسجيل");
        }
      } else {
        toast.error("كلمات المرور غير متطابقة");
      }
    } else {
      toast.error("المرجو ملئ جميع الحقول");
    }
  };

  return (
    <div className="register-main">
      <div className="register-left">
        <img src={Image} alt="background" />
      </div>
      <div className="register-right">
        <div className="register-right-container">
          <div className="register-logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="register-center">
            <form onSubmit={handleRegisterSubmit}>
              <input type="text" placeholder="الاسم" name="name" required />
              <input type="text" placeholder="النسب" name="lastname" required />
              <input type="email" placeholder="البريد الإلكتروني" name="email" required />

              <div className="pass-input-div">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="كلمة المرور"
                  name="password"
                  required
                />
                {showPassword ? (
                  <FaEyeSlash onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <FaEye onClick={() => setShowPassword(!showPassword)} />
                )}
              </div>
              <div className="pass-input-div">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="تأكيد كلمة المرور"
                  name="confirmPassword"
                  required
                />
                {showPassword ? (
                  <FaEyeSlash onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <FaEye onClick={() => setShowPassword(!showPassword)} />
                )}
              </div>

              {/* Gender Selection */}
              <div style={{ width: "100%" }}>
                <label htmlFor="gender">اختيار الجنس</label>
                <select
                  id="gender"
                  name="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  required
                >
                  <option value="" disabled>اختيار الجنس</option>
                  <option value="male">ذكر</option>
                  <option value="female">أنثى</option>
                </select>
              </div>

              <div className="register-center-buttons">
                <button type="submit">إنشاء حساب</button>
                <button type="submit">
                  <img src={GoogleSvg} alt="Google" />
                  تسجيل الدخول باستخدام Google
                </button>
              </div>
            </form>
          </div>
          <p className="login-bottom-p">
            هل لديك حساب؟ <Link to="/login">دخول</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
