import React from 'react';
import LogoR from '../../../assets/images/logoRM1.png';

const Header = ({ user_info }) => {
  return (
    <div className="block1">
      <div className="block blockf1">
        Royaume du Maroc<br />
        Ministère de l'Éducation nationale du Préscolaire et des Sports<br />
        l'Académie Régionale de l'Éducation et de Formation de {user_info.region_fr}<br />
        La direction régionale de {user_info.province_fr}
      </div>

      <div className="block">
        <img className="blockimg" src={LogoR} alt="Logo" />
      </div>

      <div className="block blockf2">
        المملكة المغربية<br />
        وزارة التربية الوطنية والتعليم الأولي و الرياضة<br />
        الأكاديمية الجهوية للتربية والتكوين لجهة {user_info.region_ar}<br />
        المديرية الإقليمية {user_info.province_ar}
      </div>
    </div>
  );
};

// Make sure to export the component as default
export default Header;
