import React, { useEffect, useState } from 'react';

function TimetableContent({ statut_in, level_in, user_info }) {
  const [content, setContent] = useState('');

  // Determine the table language (fr or ar) based on statut_in and level_in
  const table = statut_in === 'fr' ? 'fr' : statut_in === 'ar'? 'ar' : ['1', '2', '3', '1+2', '3+4', '1+2+3'].includes(level_in) ? 'ar':  'fr' ;
  // Determine the statNv based on statut_in and level_in
  const statNv = (() => {
    if (statut_in === 'fr') {
      switch (level_in) {
        case '1': return 'IF1aep';
        case '2': return 'IF2aep';
        case '3': return 'IF3aep';
        case '4': return 'IF4aep';
        case '5':
        case '6': return 'IF56aep';
        case '1+2': return 'com_fr1';
        case '3+4': return 'com_fr2';
        case '5+6': return 'IF56aep';
        case '1+2+3': return 'com_fr4';
        case '4+5+6':
        case '3+4+5+6': return 'com_fr5';
        default: return 'IF1aep';
      }
    } else if (statut_in === 'ar') {
      switch (level_in) {
        case '1': return 'IA1aep';
        case '2': return 'IA2aep';
        case '3': return 'IA3aep';
        case '4': return 'IA4aep';
        case '5':
        case '6': return 'IA56aep';
        case '1+2': return 'com_ar1';
        case '3+4': return 'com_ar2';
        case '5+6': return 'IA56aep';
        case '1+2+3': return 'com_ar4';
        case '4+5+6':
        case '3+4+5+6': return 'com_ar5';
        default: return 'IA1aep';
      }
    } else {
      switch (level_in) {
        case '1': return 'D1aep';
        case '2': return 'D2aep';
        case '3': return 'D3aep';
        case '4': return 'D4aep';
        case '5':
        case '6': return 'D56aep';
        case '1+2': return 'dcom_1';
        case '3+4': return 'dcom_3';
        case '5+6': return 'D56aep';
        case '1+2+3': return 'dcom_2';
        case '4+5+6':
        case '3+4+5+6': return 'dcom_5';
        default: return 'dcom_2';
      }
    }
  })();
  
  useEffect(() => {
    const url = table === 'fr' ? 'https://hakibaty.ma/data/emploi.php' : 'https://hakibaty.ma/data/headar.php';
    const requestBody = {
      ...user_info, // Spread existing user_info properties
      statNv,       // Add statNv to the body
    };
    // Prepare the POST request with user_info and statNv
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ requestBody }),  // Sending user_info and statNv to the server
    })
      .then(response => response.text())  // Expecting HTML content
      .then(data => setContent(data))
      .catch(error => {
        console.error('Error fetching PHP content:', error);
        setContent('<p>Failed to load content.</p>');
      });
  }, [table, user_info, statNv]);  // Add statNv to dependencies

  return (
    <div style={{ direction: table === 'fr' ? 'ltr' : 'rtl', textAlign: table === 'fr' ? 'left' : 'right' }}>
      {/* Render the fetched HTML content */}
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}

export default TimetableContent;
