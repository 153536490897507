import axios from 'axios';

// تعيين عنوان URL الأساسي لخادم API
const apiUrl = 'https://hakibaty.ma/data';

// دالة لإرسال بيانات التسجيل
export const registerUser = async (formData) => {
  try {
    const response = await axios.post(`${apiUrl}/register.php`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// دالة لتسجيل الدخول
export const loginUser = async (formData) => {
    try {
      const response = await axios.post(`${apiUrl}/login.php`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Fetch user data by ID
export const fetchUserData = async (id) => {
    try {
      const response = await axios.post(`${apiUrl}/fetch_data.php`, { id });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  