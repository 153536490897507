import React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Typography, styled, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { BarChartOutlined, HomeOutlined, HomeRepairServiceOutlined, TableChartOutlined } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useLocation, useNavigate } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import LogoImage from "../assets/logo.png"; // Ensure this path is correct

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Logo = ({ altText = 'Logo', ...props }) => {
  return (
    <img src={LogoImage} alt={altText} {...props} />
  );
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const list1 = [
  { text: "الرئيسية", icon: <HomeOutlined />, path: "/dashboard" },
  { text: "حقيبتي", icon: <HomeRepairServiceOutlined />, path: "/dashboard/hakiba" },
  { text: "إستعمال الزمن", icon: <TableChartOutlined />, path: "/dashboard/emploi" },
  { text: "التقويم التشخيصي", icon: <BarChartOutlined />, path: "/dashboard/eval" },
  { text: "المذكرة اليومية", icon: <CalendarMonthIcon />, path: "/dashboard/calendar" },
  { text: "إتصل بنا", icon: <ContactSupportIcon />, path: "/dashboard/contact" },
];

const SideBar = ({ open, handleDrawerClose, userInfo }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const userName = userInfo?.id?.username || "Loading...";
  const gender = userInfo?.id?.sex || "Loading...";
  
  let genderText;
  switch (gender) {
    case 'female':
      genderText = 'أستاذة';
      break;
    default:
      genderText = 'أستاذ';
      break;
  }

  const handleItemClick = (path) => {
    navigate(path);
    handleDrawerClose(); // Close the sidebar after navigation
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <Logo altText="Logo" style={{ height: '50px', width: '50px', alignItems: 'center' }} />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Avatar
        sx={{ mx: "auto", width: open ? 88 : 44, height: open ? 88 : 44, my: 2, border: "2px solid grey", transition: "0.25s" }}
        alt="User Avatar"
        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
      />
      <Typography 
        variant="body1" 
        align="center" 
        sx={{ textTransform: 'capitalize', fontSize: open ? 17 : 0, transition: "0.25s" }}
      >
        {userName}
      </Typography>
      <Typography 
        variant="body1" 
        align="center" 
        sx={{ fontSize: open ? 15 : 0, transition: "0.25s", color: theme.palette.info.main }}
      >
        {genderText}
      </Typography>
      <Divider />
      <List>
        {list1.map((item) => (
          <ListItem key={item.path} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                bgcolor: location.pathname === item.path ? theme.palette.mode === "light" ? grey[300] : grey[800] : null,
              }}
              onClick={() => handleItemClick(item.path)} // Call the function here
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default SideBar;
