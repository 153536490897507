import React from 'react';
import './styles/Eval.css'; // قم بإنشاء هذا الملف لاحقًا لتنسيق المكون

function Eval() {
  return (
    <div className="intro-section">
      <h1>خدمة التقويم التشخيصي قريباً</h1>
      <p>
        نحن نعمل حالياً على تطوير خدمة التقويم التشخيصي التي ستتيح للأساتذة إعداد تقارير 
        دقيقة وشاملة بمساعدة الذكاء الاصطناعي. 
      </p>
      <p>
        هذه الخدمة ستمكنك من تقييم مستوى طلابك بشكل فعال ومتابعة تقدمهم باستمرار 
        من خلال تقارير تحليلية مفصلة.
      </p>
      <p>ترقبوا إطلاق الخدمة قريباً، ونحن على استعداد لتلقي اقتراحاتكم وملاحظاتكم.</p>
    </div>
  );
}

export default Eval;
