import React, { useState } from "react";
import { toast } from 'react-toastify';

const SecondForm = ({ onSubmit, data: prevData }) => {

    const inputStyle = {
        direction: 'rtl',
        textAlign: 'right',
        padding: '10px',
        margin: '5px 0',
        width: '100%',
        boxSizing: 'border-box'
    };

    const [shifts, setShifts] = useState({
        lundi: "",
        mardi: "",
        mercredi: "",
        jeudi: "",
        vendredi: "",
        samedi: ""
    });
    const [selectedTimeMor, setSelectedTimeMor] = useState("08:30");
    const [selectedTimeEvn, setSelectedTimeEvn] = useState("13:20");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const time_mor = ["08:30", "09:00"];
    const time_evn = ["13:10", "13:40"];

    const shiftOptions = {
        "صباح": "m",
        "مساء": "s"
    };

    const daysMapping = {
        "الاثنين": "lundi",
        "الثلاثاء": "mardi",
        "الأربعاء": "mercredi",
        "الخميس": "jeudi",
        "الجمعة": "vendredi",
        "السبت": "samedi"
    };

    const daysOfWeek = ["الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"];

    const handleShiftChange = (day, value) => {
        const frenchDay = daysMapping[day];
        const frenchShift = shiftOptions[value];
        setShifts({ ...shifts, [frenchDay]: frenchShift });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Merging the passed initialData and the current form data
        const formData = {
            ...prevData,  // Spread in the previous form data
            lun: shifts.lundi,
            mar: shifts.mardi,
            mer: shifts.mercredi,
            jeu: shifts.jeudi,
            ven: shifts.vendredi,
            sam: shifts.samedi,
            time_mor: selectedTimeMor,
            time_evn: selectedTimeEvn,
        };

        try {
            onSubmit(formData);  // Call onSubmit to pass merged data to the next step
            toast.success("تم الإرسال بنجاح");
        } catch (error) {
            if (error.request) {
                toast.error("لا يوجد استجابة من الخادم، يرجى المحاولة لاحقاً");
            } else {
                toast.error("خطأ غير متوقع: " + error.message);
            }
            console.error("Error:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="account-form1">
            <form className="register-form active" onSubmit={handleSubmit}>
                <h3>تفاصيل أوقات العمل</h3>
                <div className="time-input-container">
                    <label htmlFor="timeMor">توقيت الدخول في الصباح</label>
                    <input
                        type="time"
                        style={inputStyle}
                        value={selectedTimeMor}
                        onChange={(e) => setSelectedTimeMor(e.target.value)}
                        required
                    />
                </div>
                <div className="time-input-container">
                    <label htmlFor="timeEvn">توقيت الدخول في المساء</label>
                    <input
                        type="time"
                        style={inputStyle}
                        value={selectedTimeEvn}
                        onChange={(e) => setSelectedTimeEvn(e.target.value)}
                        required
                    />
                </div>

                {daysOfWeek.map((day, index) => (
                    <select
                        key={index}
                        value={Object.keys(shiftOptions).find(key => shiftOptions[key] === shifts[daysMapping[day]]) || ""}
                        onChange={(e) => handleShiftChange(day, e.target.value)}
                        required
                    >
                        <option value="">{day}</option>
                        {Object.keys(shiftOptions).map((shift, idx) => (
                            <option key={idx} value={shift}>
                                {shift}
                            </option>
                        ))}
                    </select>
                ))}

                <button type="submit" className="btn" disabled={isSubmitting}>
                    {isSubmitting ? "جاري الإرسال..." : "متابعة"}
                </button>
            </form>
        </div>
    );
};

export default SecondForm;
