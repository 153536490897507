import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import Logo from "../assets/logo.png";
import "../styles/Landing.css";
import HomeSection from '../components/HomeSection'; // استيراد مكون HomeSection
import AboutSection from '../components/AboutSection'; // استيراد مكون AboutSection
import Subjects from '../components/Subjects';
import Footer from '../components/FooterSection';
import HelpCenter from '../components/HelpCenter';
import FAQ from '../components/Faq';
import Contact from '../components/Contact';
import FeedbackForm from '../components/Feedback';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsOfUse from '../components/TermsOfUse';

const Landing = () => {
  const location = useLocation(); // الحصول على الموقع الحالي

  // Navbar toggle state and scroll-to-top effect on route change
  useEffect(() => {
    const menuBtn = document.querySelector('#menu-btn');
    const closeNavbar = document.querySelector('#close-navbar');
    const navbar = document.querySelector('.header .navbar');

    if (menuBtn) {
      menuBtn.onclick = () => {
        navbar?.classList.toggle('active');
      };
    }

    if (closeNavbar) {
      closeNavbar.onclick = () => {
        navbar?.classList.remove('active');
      };
    }

    const registerBtn = document.querySelector('.account-form .register-btn');
    const loginBtn = document.querySelector('.account-form .login-btn');

    if (registerBtn && loginBtn) {
      registerBtn.onclick = () => {
        registerBtn.classList.add('active');
        loginBtn.classList.remove('active');
        document.querySelector('.account-form .login-form').classList.remove('active');
        document.querySelector('.account-form .register-form').classList.add('active');
      };

      loginBtn.onclick = () => {
        registerBtn.classList.remove('active');
        loginBtn.classList.add('active');
        document.querySelector('.account-form .login-form').classList.add('active');
        document.querySelector('.account-form .register-form').classList.remove('active');
      };
    }

    // Smooth scroll to top on route change for all components
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [location]); // This will trigger when the route changes

  return (
    <div className="landing-main">
      <header className="header">
        <Link to="/" className="logo">
          <img src={Logo} alt="logo" />
        </Link>

        <nav className="navbar">
          <div id="close-navbar">
            <ClearOutlinedIcon style={{fontSize: '1.5rem'}} />
          </div>
          <Link to="/" className="active">الرئيسية</Link>
          <Link to="/about">عن الموقع</Link>
          <Link to="/subjects">الخدمات</Link>
          <Link to="/contact">اتصل بنا</Link>
        </nav>

        <div className="icons">
          <div id="menu-btn">
            <ReorderOutlinedIcon style={{fontSize: '1.5rem'}} />
          </div>
        </div>
      </header>

      {/* Render HomeSection only when the current path is '/' */}
      {location.pathname === '/' && <HomeSection />}

      {/* Render AboutSection only when the current path is '/about' */}
      {location.pathname === '/about' && <AboutSection />}

      {/* Render Contact when on '/contact' */}
      {location.pathname === '/contact' && <Contact />}

      {/* Render Subjects when on '/subjects' */}
      {location.pathname === '/subjects' && <Subjects />}

      {/* Render FAQ when on '/faq' */}
      {location.pathname === '/faq' && <FAQ />}

      {/* Render HelpCenter when on '/helpcenter' */}
      {location.pathname === '/helpcenter' && <HelpCenter />}


      {/* Render Feedback when on '/helpcenter' */}
      {location.pathname === '/feedback' && <FeedbackForm />}


      {/* Render Feedback when on '/helpcenter' */}
      {location.pathname === '/privacy' && <PrivacyPolicy />}
      
            {/* Render Feedback when on '/helpcenter' */}
            {location.pathname === '/terms' && <TermsOfUse />}

      {/* Footer remains static across all components */}
      <Subjects />
      <Footer />
    </div>
  );
};

export default Landing;
