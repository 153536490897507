import React from 'react';
import '../styles/Landing.css'; // Optional: Custom styles for the FAQ section

const FAQ = () => {
  return (
    <section className="faq">
      <div className="container">
        <h1 className="heading">الأسئلة الشائعة</h1>
        
        <div className="faq-card">
          <h2>ما هي "حقيبتي"؟</h2>
          <div className="content">
            <p>"حقيبتي" هي منصة تعليمية إلكترونية تهدف إلى دعم الأساتذة في تنظيم وتحضير الدروس والمواد التعليمية بشكل فعال وسهل.</p>
          </div>
        </div>

        <div className="faq-card">
          <h2>كيف يمكنني التسجيل في الموقع؟</h2>
          <div className="content">
            <p>يمكنك التسجيل عن طريق النقر على زر "التسجيل" في صفحة الدخول وملء المعلومات المطلوبة لإنشاء حساب جديد.</p>
          </div>
        </div>

        <div className="faq-card">
          <h2>هل يمكنني استيراد الجداول الدراسية الخاصة بي؟</h2>
          <div className="content">
            <p>نعم، يمكنك استيراد الجداول الدراسية الخاصة بك واستخدام أدوات التخطيط في "حقيبتي" لتخصيصها وتعديلها حسب الحاجة.</p>
          </div>
        </div>

        <div className="faq-card">
          <h2>كيف يمكنني الوصول إلى تقارير التقييم؟</h2>
          <div className="content">
            <p>يمكنك الوصول إلى تقارير التقييم من خلال قسم التقارير في لوحة التحكم الخاصة بك، حيث يمكنك عرض وتحليل التقييمات التي تم إنشاؤها.</p>
          </div>
        </div>

        <div className="faq-card">
          <h2>هل توجد نسخة تجريبية مجانية؟</h2>
          <div className="content">
            <p>نعم، نقدم نسخة تجريبية مجانية للمستخدمين الجدد تتيح لهم تجربة جميع الميزات المتاحة قبل الاشتراك في الخدمة الكاملة.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
