import React from 'react';
import '../styles/Landing.css'; // Optional: Custom styles for the Help Center

const HelpCenter = () => {
  return (
    <section className="help-center">
      <h1 className="section-title">مركز المساعدة</h1>

      <div className="faq">
        <h3>ما هي الخدمات التي يقدمها هذا الموقع؟</h3>
        <p>نحن نقدم مجموعة متنوعة من الخدمات لمساعدة الأساتذة في تحضير الدروس والوثائق الخاصة بهم بطرق مبتكرة وفعالة.</p>

        <h3>كيف يمكنني التواصل مع فريق الدعم الفني؟</h3>
        <p>يمكنك التواصل معنا عبر البريد الإلكتروني أو الهاتف للحصول على الدعم الفني المطلوب.</p>

        <h3>هل هناك رسوم لاستخدام الموقع؟</h3>
        <p>نعم، هناك بعض الرسوم للاشتراك في الخدمات المميزة التي نقدمها. يمكنك الاطلاع على خطط الأسعار لدينا لمزيد من التفاصيل.</p>
      </div>

      <div className="contact-options">
        <div className="option">
          <i className="fas fa-phone"></i>
          <h3>الهاتف</h3>
          <p>212668156657+</p>
        </div>
        <div className="option">
          <i className="fas fa-envelope"></i>
          <h3>البريد الإلكتروني</h3>
          <p>support@hakibaty.ma</p>
        </div>
        <div className="option">
          <i className="fas fa-comments"></i>
          <h3>الموقع</h3>
          <p> المغرب</p>
        </div>
      </div>

      <div className="resources">
        <h2>روابط مفيدة</h2>
        <a href="#">الأسئلة الشائعة</a>
        <a href="#">الدليل الإرشادي</a>
        <a href="#">السياسات والشروط</a>
        <a href="#">الخصوصية والأمان</a>
      </div>
    </section>
  );
};

export default HelpCenter;
