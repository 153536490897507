import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // إرسال البيانات إلى الخادم باستخدام axios
      const response = await axios.post('https://hakibaty.ma/data/submitFeedback.php', new URLSearchParams(formData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      toast.success(response.data.message); // عرض رسالة النجاح
      // يمكنك مسح الحقول بعد الإرسال إذا أردت
      setFormData({ name: '', email: '', message: '' });
      
      // الانتقال إلى الصفحة الرئيسية بعد النجاح
      navigate('/');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('حدث خطأ أثناء إرسال الملاحظات'); // عرض رسالة الخطأ
    }
  };

  return (
    <section className="feedback">
      <div className="container">
        <h1 className="heading">أرسل ملاحظاتك</h1>
        <div className="feedback-form">
          <h2>نحن نقدر ملاحظاتك</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="أدخل اسمك"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="أدخل بريدك الإلكتروني"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="أدخل ملاحظاتك هنا"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <input type="submit" value="أرسل الملاحظات" />
          </form>
        </div>
      </div>
      <ToastContainer /> {/* مكان عرض الرسائل */}
    </section>
  );
};

export default FeedbackForm;
