import React from 'react';
import '../dash/styles/First.css';
import { Button } from '@mui/material';
import { DescriptionOutlined } from "@mui/icons-material";
import sub4 from "../../assets/images/pdfToWord/image1.png";
import sub3 from "../../assets/images/pdfToWord/image2.png";
import sub2 from "../../assets/images/pdfToWord/image3.png";
import sub1 from "../../assets/images/pdfToWord/image4.png";
import sub5 from "../../assets/images/pdfToWord/image5.png";

function ConvertToWord() {
  const handleVisitCloudConvert = () => {
    window.open('https://cloudconvert.com/pdf-to-docx', '_blank');
  };

  return (
    <div className="intro-section">
      <h1>كيفية تحويل ملف PDF إلى DOCX</h1>
      <p>
         لتحويل ملفات PDF إلى مستندات Word بصيغة DOCX بسهولة. 
        اتبع الخطوات التالية للقيام بذلك:
      </p>

      <p>
      بعد الضغط على الزر
      </p>
      <p>

      <Button
        sx={{ padding: "6px 8px", textTransform: "capitalize", mt: 2 }} // Added margin top for spacing
        variant="contained"
        color="secondary"
        onClick={handleVisitCloudConvert}
      >
        <DescriptionOutlined />
        تحويل إلى Word
      </Button>
      </p>

      <p>
       سيتم تحويلك إلى صفحة المُحَوِّل
      </p>

      <ul>
        <li>
          قم بالنقر على زر "اختيار الملفات".
          <img src={sub1} alt="اختيار الملفات" style={{ maxWidth: '100%', marginTop: '8px' }} />
        </li>
        <li>
        ثم قم بالنقر على زر "Media".
          <img src={sub2} alt="اختيار صيغة DOCX" style={{ maxWidth: '100%', marginTop: '8px' }} />
        </li>
        <li>
        قم بإختيار "Emploi.PDF" الذي قمت بتحميله سابقا من قائمة التحميلات الخاصة بك
          <img src={sub3} alt="اختيار صيغة DOCX" style={{ maxWidth: '100%', marginTop: '8px' }} />
        </li>
        <li>
          انقر على زر "بدء التحويل" لبدء عملية التحويل.
          <img src={sub4} alt="بدء التحويل" style={{ maxWidth: '100%', marginTop: '8px' }} />
        </li>
        <li>
          بعد الانتهاء، يمكنك تنزيل ملف DOCX المحَوَّل من الرابط المتاح.
          <img src={sub5} alt="تنزيل الملف المحول" style={{ maxWidth: '100%', marginTop: '8px' }} />
        </li>
      </ul>
      <Button
        sx={{ padding: "6px 8px", textTransform: "capitalize", mt: 2 }} // Added margin top for spacing
        variant="contained"
        color="secondary"
        onClick={handleVisitCloudConvert}
      >
        <DescriptionOutlined />
        تحويل إلى Word
      </Button>
      <p>
        تأكد من مراجعة المستند المحول للتأكد من أن جميع التنسيقات والمحتويات قد تم تحويلها بشكل صحيح.
      </p>
    </div>
  );
}

export default ConvertToWord;
