import React, { useState, useEffect } from 'react';
import contactImage from "../assets/images/logos/contact-image.jpg"; // Adjust the path according to your project structure
import { Box, Grid, Typography, TextField, Button, TextareaAutosize, Card, CardContent } from "@mui/material";
import { Phone, Email, LocationOn } from '@mui/icons-material'; // Material Icons
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: ''
  });

  const navigate = useNavigate();
  const location = useLocation();

  // Show toast when the component mounts
  useEffect(() => {
    if (location.pathname === '/contact') {
      toast.info('مرحبًا بكم في صفحة الاتصال!'); // Display a toast message
      toast.success('تم إرسال الرسالة بنجاح!');
    }
  }, [location.pathname]); // Dependency array to rerun effect on pathname change

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://hakibaty.ma/data/contact_form.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (result.success) {
        toast.success('تم إرسال الرسالة بنجاح!');
        setFormData({
          fullName: '',
          email: '',
          subject: '',
          message: ''
        });

        if (location.pathname === '/contact') {
          navigate('/');
        }
      } else {
        toast.error('حدث خطأ أثناء إرسال الرسالة.');
      }
    } catch (error) {
      toast.error('خطأ في الاتصال بالخادم.');
    }
  };
  

  return (
    <Box sx={{ textAlign: "center", mb: 3, p: 2, backgroundColor: "var(--black)" }}>
      <Typography variant="h6" sx={{ mb: 2, color: "var(--black)" }}>
  مرحباً بكم في تطبيقنا المبتكر، المصمم خصيصًا لأساتذة التعليم الابتدائي. نحن فخورون بأن نقدم لكم الأول من نوعه في المغرب، الذي يسعى لتلبية احتياجاتكم التعليمية والإدارية. 
  يهدف هذا التطبيق إلى تسهيل عملية التخطيط والإدارة اليومية للصفوف الدراسية، مما يوفر عليكم الوقت والجهد، ويساعدكم في التركيز على تقديم تعليم متميز.
  <br /><br />
  نحن في المرحلة الأولى من تطوير التطبيق، ونرحب بكل ملاحظاتكم وتوجيهاتكم. إن آرائكم تهمنا وتساعدنا في تحسين التطبيق ليكون أكثر فعالية وملاءمة لاحتياجاتكم. 
  دعونا نعمل معًا على بناء أداة قادرة على دعمكم في مهامكم التعليمية. 
  نشكركم على دعمكم المستمر واهتمامكم!
</Typography>

      {/* Form and Image Section */}
      <Grid container spacing={4} sx={{ mt: 4 }} justifyContent="center" alignItems="center">
        
        {/* Image */}
        <Grid item xs={12} md={6}>
          <img
            src={contactImage}
            alt="Contact"
            style={{ width: "100%", borderRadius: "8px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}
          />
        </Grid>

        {/* Contact Form */}
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, p: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
              تواصل معنا
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="الاسم الكامل"
                variant="outlined"
                fullWidth
                sx={{ mb: 3 }}
                required
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
              />
              <TextField
                label="البريد الإلكتروني"
                type="email"
                variant="outlined"
                fullWidth
                sx={{ mb: 3 }}
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <TextField
                label="الموضوع"
                variant="outlined"
                fullWidth
                sx={{ mb: 3 }}
                required
                name="subject"
                value={formData.subject}
                onChange={handleChange}
              />
              <TextareaAutosize
                minRows={5}
                placeholder="رسالتك"
                style={{
                  width: '100%',
                  padding: '15px',
                  borderRadius: '8px',
                  border: '1px solid #c4c4c4',
                  fontSize: '16px',
                  marginBottom: '16px',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)'
                }}
                required
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ py: 1.5, fontSize: '16px', fontWeight: 'bold' }}
              >
                إرسال
              </Button>
            </form>
          </Card>
        </Grid>
      </Grid>
      
      <Grid container justifyContent="center" spacing={4}>
        {/* Contact Information Cards */}
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Phone sx={{ fontSize: 40, color: "#1976d2", mb: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>الهاتف</Typography>
              <Typography variant="body1">+2126-68-15-66-57</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Email sx={{ fontSize: 40, color: "#1976d2", mb: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>البريد الإلكتروني</Typography>
              <Typography variant="body1">contact@hakibaty.ma</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <LocationOn sx={{ fontSize: 40, color: "#1976d2", mb: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>الموقع</Typography>
              <Typography variant="body1">المغرب</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
