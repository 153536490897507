import React, { useState, useEffect } from 'react';
import './styles/Eval.css'; // قم بإنشاء هذا الملف لاحقًا لتنسيق المكون


function DailyReminder() {
  return (
    <div className="intro-section">
      <h1>خدمة المذكرة اليومية قريباً</h1>
      <p>
        نحن نعمل حالياً على تطوير خدمة المذكرة اليومية التي ستساعد الأساتذة 
        في تنظيم ومتابعة الدروس والمواضيع بشكل يومي.
      </p>
      <p>
        ستتيح لك هذه الخدمة إنشاء جدول زمني مرن للمذكرة، مما يسهل عليك 
        تذكير الطلاب بالمواضيع التي يجب عليهم مراجعتها.
      </p>
      <p>ترقبوا إطلاق الخدمة قريباً، ونحن نتطلع إلى تقديم المزيد من الدعم لكم.</p>
    </div>
  );
}

export default DailyReminder;
