import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Snackbar, CircularProgress } from "@mui/material";
import { DownloadOutlined, DescriptionOutlined } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './forms/styles/Emploi.css';
import Timetable from './components/Timetable';
import Header from './components/Header';
import TimetableContent from './components/TimetableContent';
import RadioGroupRating from './RadioGroupRating';

function EmploiUser({ emploiInfo, userData }) {
  const navigate = useNavigate(); // Initialize useNavigate
  const [user_info, setUser_info] = useState(emploiInfo);
  const [pdfData, setPdfData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const statut_in = user_info?.statut_in;
  const level_in = user_info?.level_in;
  const displayName = user_info?.firstName;
  const [sexe, setSexe] = useState(userData?.id?.sex || '');
  const [userId, setUserId] = useState(userData?.id?.id || '');
  const table = statut_in === 'fr' ? 'fr' : statut_in === 'ar' ? 'ar' : ['1', '2', '3', '1+2', '3+4', '1+2+3'].includes(level_in) ? 'ar' : 'fr';

  const statNv = (() => {
    if (statut_in === 'fr') {
      switch (level_in) {
        case '1': return 'IF1aep';
        case '2': return 'IF2aep';
        case '3': return 'IF3aep';
        case '4': return 'IF4aep';
        case '5':
        case '6': return 'IF56aep';
        case '1+2': return 'com_fr1';
        case '3+4': return 'com_fr2';
        case '5+6': return 'IF56aep';
        case '1+2+3': return 'com_fr4';
        case '4+5+6':
        case '3+4+5+6': return 'com_fr5';
        default: return 'IF1aep';
      }
    } else if (statut_in === 'ar') {
      switch (level_in) {
        case '1': return 'IA1aep';
        case '2': return 'IA2aep';
        case '3': return 'IA3aep';
        case '4': return 'IA4aep';
        case '5':
        case '6': return 'IA56aep';
        case '1+2': return 'com_ar1';
        case '3+4': return 'com_ar2';
        case '5+6': return 'IA56aep';
        case '1+2+3': return 'com_ar4';
        case '4+5+6':
        case '3+4+5+6': return 'com_ar5';
        default: return 'IA1aep';
      }
    } else {
      switch (level_in) {
        case '1': return 'D1aep';
        case '2': return 'D2aep';
        case '3': return 'D3aep';
        case '4': return 'D4aep';
        case '5':
        case '6': return 'D56aep';
        case '1+2': return 'dcom_1';
        case '3+4': return 'dcom_3';
        case '5+6': return 'D56aep';
        case '1+2+3': return 'dcom_2';
        case '4+5+6':
        case '3+4+5+6': return 'dcom_5';
        default: return 'dcom_2';
      }
    }
  })();

  useEffect(() => {
    if (userData) {
      setSexe(userData?.id?.sex);
    }
  }, [userData]);

  const getSexeLabel = () => {
    if (table === 'fr') {
      return sexe === 'male' ? 'Professeur' : sexe === 'female' ? 'Professeure' : '';
    } else if (table === 'ar') {
      return sexe === 'male' ? 'الأستاذ' : sexe === 'female' ? 'الأستاذة' : '';
    }
  };

  const requestBody = {
    ...user_info,
    statNv,
    sexe: getSexeLabel(),
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      setLoading(true);
      try {
        const url = table === 'fr'
          ? 'https://hakibaty.ma/data/emploi_composer/com_frd.php' 
          : 'https://hakibaty.ma/data/emploi_composer/com_ar1.php';

        const response = await axios({
          url,
          method: 'POST',
          data: { requestBody },
          responseType: 'blob',
        });

        setPdfData(response.data);
      } catch (error) {
        console.error("Error downloading the file:", error);
        setError("حدث خطأ أثناء تحميل الملف.");
      } finally {
        setLoading(false);
      }
    };

    if (user_info) {
      fetchUserInfo();
    }
  }, [user_info, table]);

  const handleDownloadPdf = () => {
    try {
      if (pdfData) {
        const url = window.URL.createObjectURL(new Blob([pdfData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Emploi ${displayName}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
      setError("حدث خطأ أثناء تنزيل PDF.");
    }
  };

  const handleConvertToWord = () => {
    navigate('/dashboard/doc'); // Navigate to the desired route
  };

  return (
    <>
      <Box sx={{ textAlign: "right", mb: 1.3 }}>


        <Snackbar
          open={Boolean(error)}
          onClose={() => setError('')}
          message={error}
          autoHideDuration={6000}
        />
        <Button
          sx={{ padding: "6px 8px", textTransform: "capitalize" }}
          variant="contained"
          color="primary"
          onClick={handleDownloadPdf}
          disabled={loading}
        >
          <DownloadOutlined />
          تحميل إستعمال الزمن
        </Button>

        <Button
          sx={{padding: "6px 8px", textTransform: "capitalize", ml: 1 }}
          variant="contained"
          color="secondary"
          onClick={handleConvertToWord}
          disabled={loading}
        >
          <DescriptionOutlined />
          تحويل إلى Word
        </Button>
        <Box sx={{textAlign: "center", my: 2, p: 1, border: '2px solid #ccc', borderRadius: '4px' }}>
        <RadioGroupRating userId={userId} />
        </Box>
        {loading && <CircularProgress sx={{ ml: 1 }} />}
      </Box>

      <Box sx={{ my: 2, p: 1, border: '1px solid #ccc', borderRadius: '4px' }}>
        <div className="body">
          <div className="parent">
            <Header user_info={user_info} />
            <Timetable user_info={user_info} sexe={getSexeLabel()} />
            <TimetableContent statut_in={user_info.statut_in} level_in={user_info.level_in} user_info={user_info} />
          </div>
        </div>
      </Box>
    </>
  );
}

export default EmploiUser;
