import React, { useEffect, useState } from 'react';

const Timetable = ({ user_info, sexe }) => {
  const [userData, setUserData] = useState({});
  const [sex, setSex] = useState(sexe || ''); // Initialize with userSexe or empty string

  useEffect(() => {
    if (user_info) {
      setUserData(user_info);
    }
  }, [user_info]);

  useEffect(() => {
    if (sexe) {
      setSex(sexe); // Update state when userSexe prop changes
    }
  }, [sexe]);
  // Determine if the table should be in French or Arabic
  const table = userData.statut_in === 'fr' 
    ? 'fr' 
    : userData.statut_in === 'ar' 
      ? 'ar' 
      : ['1', '2', '3', '1+2', '3+4', '1+2+3'].includes(userData.level_in) 
        ? 'ar' 
        : 'fr';


  const renderTable = () => {
    if (table === 'fr') {
      return (
        <table className="timetable">
          <thead>
            <tr>
              <th>Etablissement</th>
              <td>{userData.ss_name || ''}</td>
              <td rowSpan="3"></td>
              <th>{sexe}</th>
              <td>{`${userData.firstName || ''} ${userData.lastName || ''}`}</td>
            </tr>
            <tr>
              <th>Unité scolaire</th>
              <td>{userData.u_Scolaire || ''}</td>
              <th>DOTI</th>
              <td>{userData.n_Ddr || ''}</td>
            </tr>
            <tr>
              <th>Année scolaire</th>
              <td>2024-2025</td>
              <th>Niveaux</th>
              <td>{`${userData.level || ''} | ${userData.statut || ''}`}</td>
            </tr>
          </thead>
        </table>
      );
    } else {
      return (
        <table className="timetable">
          <thead>
            <tr>
              <td>{`${userData.firstName || ''} ${userData.lastName || ''}`}</td>
              <th>{sexe}</th>
              <td rowSpan="3"></td>
              <td>{userData.ss_name || ''}</td>
              <th>المؤسسة</th>
            </tr>
            <tr>
              <td>{userData.n_Ddr || ''}</td>
              <th>رقم التأجير</th>
              <td>{userData.u_Scolaire || ''}</td>
              <th>الوحدة المدرسية</th>
            </tr>
            <tr>
              <td>{`${userData.level || ''} | ${userData.statut || ''}`}</td>
              <th>المستويات</th>
              <td>2024-2025</td>
              <th>السنة الدراسية</th>
            </tr>
          </thead>
        </table>
      );
    }
  };

  return (
    <div className="block2">
      {renderTable()}
      <div className="stylemploi">
        {table === 'fr' ? 'EMPLOI DU TEMPS' : 'إستعمال الزمن'}
      </div>
      <div>
      </div>
    </div>
  );
};

export default Timetable;
