import React, { useEffect } from 'react';
import { Box, IconButton, Stack, Toolbar, styled, useTheme } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import AccountPopover from './common/account-popover';

// Define styled components
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const drawerWidth = 240;

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

function TopBar({ open, handleDrawerOpen, setMode, userInfo }) {
  const theme = useTheme();
  
  // Update localStorage whenever the theme mode changes
  useEffect(() => {
    localStorage.setItem("currentMode", theme.palette.mode);
  }, [theme.palette.mode]);

  // Toggle theme mode
  const handleModeChange = () => {
    const newMode = theme.palette.mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
  };

  return (
    <AppBar position="fixed" sx={{ ...(open && { marginLeft: drawerWidth, width: `calc(100% - ${drawerWidth}px)` }) }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="…  إبحث"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>

        <Box flexGrow={1} />

        <Stack direction={"row"}>
          <IconButton onClick={handleModeChange} color='inherit'>
            {theme.palette.mode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
          </IconButton>
          <AccountPopover userInfo={userInfo} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
