import React, { useEffect } from 'react';
import "../styles/Logout.css";
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png'; // استيراد الشعار

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("id"); // إزالة الـ ID المخزن
        const timer = setTimeout(() => {
            navigate("/"); // الانتقال إلى الصفحة الرئيسية
        }, 3000);

        // تنظيف المؤقت في حال إلغاء تحميل المكون مبكرًا
        return () => clearTimeout(timer);
    }, [navigate]); // تمرير navigate كـ dependancy

    return (
      <div className="logout-main">
        <div className="logout-box">
          <div className="logout-logo">
            <img src={Logo} alt="شعار الموقع" />
          </div>
          <h1>تم تسجيل الخروج بنجاح!</h1>
          <p>سيتم توجيهك إلى الصفحة الرئيسية خلال 3 ثوانٍ...</p>
        </div>
      </div>
    );
}

export default Logout;
