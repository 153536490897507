import React, { useEffect, useState } from "react";
import Image from "../assets/image.png";
import Logo from "../assets/logo.png";
import GoogleSvg from "../assets/icons8-google.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import "../styles/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js"; // استيراد مكتبة CryptoJS
import { loginUser } from "../sevices/apiService"; // استيراد دالة تسجيل الدخول

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // استخدام useEffect للتحقق من وجود معرف في Local Storage
  useEffect(() => {
    const encryptedId = localStorage.getItem("id");
    if (encryptedId) {
      // إذا تم العثور على معرف مشفر، الانتقال مباشرة إلى صفحة /dashboard
      navigate("/dashboard");
    }
  }, [navigate]);

  const [loading, setLoading] = useState(false);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);  // تعيين حالة التحميل
  
    let email = e.target.email.value;
    let password = e.target.password.value;
  
    if (email.length > 0 && password.length > 0) {
      const formData = { email, password };
  
      try {
        const result = await loginUser(formData);
        setLoading(false);  // إيقاف التحميل
  
        if (result.status === "success") {
          const encryptedId = CryptoJS.AES.encrypt(result.id.toString(), "your-secret-key").toString();
          localStorage.setItem("id", encryptedId);
          navigate("/dashboard");
          toast.success("تم تسجيل الدخول بنجاح");
        } else if (result.message === "unf") {
          toast.error("هذا الحساب غير موجود");
        }
      } catch (err) {
        setLoading(false);  // إيقاف التحميل عند الخطأ
        toast.error("حدث خطأ أثناء تسجيل الدخول");
      }
    } else {
      setLoading(false);  // إيقاف التحميل عند الخطأ في الإدخال
      toast.error("يرجى تعبئة جميع الحقول");
    }
  };
  

  return (
    <div className="login-main">
      <div className="login-left">
        <img src={Image} alt="صورة" />
      </div>
      <div className="login-right">
        <div className="login-right-container">
          <div className="login-logo">
            <img src={Logo} alt="شعار" />
          </div>
          <div className="login-center">
            <h2>مرحبًا بعودتك!</h2>
            <p>يرجى إدخال التفاصيل الخاصة بك</p>
            <form onSubmit={handleLoginSubmit}>
              <input type="email" placeholder="البريد الإلكتروني" name="email" required />
              <div className="pass-input-div">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="كلمة المرور"
                  name="password"
                  required
                />
                {showPassword ? (
                  <FaEyeSlash onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <FaEye onClick={() => setShowPassword(!showPassword)} />
                )}
              </div>

              <div className="login-center-options">
              <Link to="/forgot-password" className="forgot-pass-link">هل نسيت كلمة المرور؟</Link>
              </div>
              <div className="login-center-buttons">
                <button type="submit">تسجيل الدخول</button>
                <button type="submit">
                  <img src={GoogleSvg} alt="" />
                  تسجيل الدخول باستخدام Google
                </button>
              </div>
            </form>
          </div>

          <p className="login-bottom-p">
            ليس لديك حساب؟ <Link to="/register">إنشاء حساب</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
