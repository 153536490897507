import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TopBar from '../components/TopBar';
import SideBar from '../components/SideBar';
import { getDesignTokens } from '../theme';
import { Outlet } from 'react-router-dom';
import "../styles/Dashboard.css";
import CryptoJS from 'crypto-js';
import { fetchUserData } from '../sevices/apiService';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function MiniDrawer() {
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [mode, setMode] = useState(Boolean(localStorage.getItem("currentMode")) ? localStorage.getItem("currentMode") : "light");
  const navigate = useNavigate();

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  useEffect(() => {
    const fetchData = async () => {
      const encryptedId = localStorage.getItem("id");
      if (!encryptedId) {
        navigate("/login");
        toast.error("المرجو تسجيل الدخول أولا!!");
        return ;
      }

      try {
        // Decrypt the ID
        const bytes = CryptoJS.AES.decrypt(encryptedId, "your-secret-key");
        const decryptedId = bytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedId) {
          throw new Error("خطأ في فك التشفير");
        }

        // Fetch user data from the API
        const result = await fetchUserData(decryptedId);
        setUserInfo(result);

        if (result.status === 'success') {
          toast.success(result.data);
        } else {
          toast.error(result.message || "فشل في جلب البيانات");
        }

      } catch (error) {
        console.error("Decryption or API error:", error);
        toast.error("خطأ في فك التشفير أو في جلب البيانات");
      }
    };

    fetchData();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <TopBar open={open} handleDrawerOpen={handleDrawerOpen} setMode={setMode} userInfo={userInfo} />
        <SideBar open={open} handleDrawerClose={handleDrawerClose} userInfo={userInfo} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Outlet/>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
