import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Landing.css'; // If you need separate CSS for the footer

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Create form data
    const formData = new FormData();
    formData.append('email', email);

    try {
      // Send data as form data instead of JSON
      const response = await axios.post('https://hakibaty.ma/data/subscribe.php', formData);
      
      if (response.data.status === 'success') {
        toast.success('تم الاشتراك بنجاح!');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('حدث خطأ أثناء الإرسال. حاول مرة أخرى.');
    }
  };

  return (
    <section className="footer">
      <div className="box-container">
        <div className="box">
          <h3>
            <Link to="/default" className="logo">
              <i className="bx bxs-briefcase-alt-2"></i> حقيبتي
            </Link>
          </h3>
          <p>موقعنا مصمم لمساعدة الأساتذة في إيجاد وتحضير جميع الوثائق والدروس الخاصة بهم بكفاءة.</p>
          <div className="share">
            <a href="https://facebook.com" className="fab fa-facebook-f"></a>
            <a href="https://instagram.com" className="fab fa-instagram"></a>
            <a href="https://twitter.com" className="fab fa-twitter"></a>
          </div>
        </div>

        <div className="box">
          <h3>روابط سريعة</h3>
          <Link to="/" className="link">الرئيسية</Link>
          <Link to="/about" className="link">عن الموقع</Link>
          <Link to="/subjects" className="link">الخدمات</Link>
          <Link to="/contact" className="link">اتصل بنا</Link>
        </div>

        <div className="box">
          <h3>روابط مفيدة</h3>
          <Link to="/helpcenter" className="link">مركز المساعدة</Link>
          <Link to="/faq" className="link">اطرح أسئلة</Link>
          <Link to="/feedback" className="link">أرسل ملاحظات</Link>
          <Link to="/privacy" className="link">سياسة الخصوصية</Link>
          <Link to="/terms" className="link">شروط الاستخدام</Link>
        </div>
        
        <div className="box">
          <h3>معلومات</h3>
          <p>اشترك للحصول على آخر التحديثات</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="أدخل بريدك الإلكتروني"
              className="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input type="submit" value="اشترك" className="btn" />
          </form>
        </div>
      </div>

      <div className="credit">
        تم الإنشاء بواسطة <span>الأستاذ. محمد ناصر الدين</span> | جميع الحقوق محفوظة! حقوق الطبع والنشر @ 2024
      </div>

      {/* ToastContainer to display toast notifications */}
      <ToastContainer  position='top-center' />
    </section>
  );
};

export default Footer;
