import axios from 'axios';
import { toast } from 'react-toastify';

// إنشاء عميل axios مع الإعدادات الافتراضية
const apiClient = axios.create({
  baseURL: 'https://hakibaty.ma/data', // عنوان URL الأساسي للـ API
  timeout: 10000, // مهلة الطلب بالمللي ثانية
  headers: {
    'Content-Type': 'application/json',
  },
});

// دالة مساعدة لمعالجة الأخطاء
const handleError = (error) => {
  if (error.response) {
    toast.error("خطأ في الخادم: " + error.response.data.message);
  } else if (error.request) {
    toast.error("لا يوجد استجابة من الخادم، يرجى المحاولة لاحقاً");
  } else {
    toast.error("خطأ غير متوقع: " + error.message);
  }
};

// دوال خدمة API
const ap = {
  // دالة لجلب معلومات المستخدم
  async fetchUserInfo(id) {
    try {
      const response = await apiClient.post('/getUserInfo.php', { id });
      return response.data; // إرجاع بيانات المستخدم
    } catch (error) {
      handleError(error); // معالجة الخطأ باستخدام دالة handleError
      throw error; // إعادة رمي الخطأ للتعامل معه في الوظيفة المستدعية إذا لزم الأمر
    }
  },

  
  // دالة لإرسال التغذية الراجعة (feedback)
  async submitFeedback(formData) {
    try {
      const response = await apiClient.post('/submitFeedback.php', formData); // استخدم endpoint مناسب مثل submitFeedback.php
      return response.data; // إرجاع البيانات من الخادم
    } catch (error) {
      handleError(error); // معالجة الخطأ باستخدام دالة handleError
      throw error; // إعادة رمي الخطأ للتعامل معه في الوظيفة المستدعية إذا لزم الأمر
    }
  },

  // يمكنك إضافة المزيد من الدوال هنا للتعامل مع API مختلف
};


// تصدير الكائن apiService
export default ap;


// دالة لإرسال بيانات النموذج
export const submitForm = async (formData) => {
  try {
    const response = await apiClient.post('submit_form.php', formData);
    return response.data;
  } catch (error) {
    if (error.response) {
      // تم إرسال الطلب وتم الرد من الخادم برمز حالة
      throw new Error(error.response.data.msg || 'حدث خطأ ما');
    } else if (error.request) {
      // تم إرسال الطلب ولكن لم يتم استلام الرد من الخادم
      throw new Error('لم يتم استلام استجابة من الخادم');
    } else {
      // حدث شيء أثناء إعداد الطلب
      throw new Error('حدث خطأ أثناء إعداد الطلب');
    }
  }
};
