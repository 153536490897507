import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { submitForm } from '../../../sevices/ap'; // Import the API method

const ThirdForm = ({ data: prevData }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [ss_name, setSs_name] = useState('');
  const [u_Scolaire, setU_Scolaire] = useState('');
  const [n_Ddr, setN_Ddr] = useState('');

  const language = prevData?.statut;
  const level = prevData?.level;

  // Determine if the form should be in French or Arabic
  const table = language === 'فرنسية' ? 'فرنسية' : language === 'عربية'? 'عربية' : ['1 ابتدائي', '2 ابتدائي', '3 ابتدائي', '1+2 ابتدائي', '3+4 ابتدائي', '1+2+3 ابتدائي'].includes(level) ? 'عربية':  'فرنسية';

  const navigate = useNavigate();  // Use navigate hook

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !ss_name || !u_Scolaire || !n_Ddr) {
      toast.error("المرجو ملء جميع الخانات");
      return;
    }

    const formData = {
      ...prevData,
      firstName,
      lastName,
      ss_name,
      u_Scolaire,
      n_Ddr
    };

    try {
      const result = await submitForm(formData);
      if (result) {
        toast.success(result.message);
        navigate('/dashboard/emploi');  // Optional navigation after completion
      } else {
        toast.error(result.message || "حدث خطأ أثناء الإرسال");
      }
    } catch (err) {
      toast.error(err.message || "حدث خطأ ما");
    }
  };

  return (
    <div className="account-form1">
      <form onSubmit={handleSubmit} className="register-form active">
        {/* Conditionally display the heading based on the selected language */}
        <h3>{table === 'فرنسية' ? 'المرجو إدخال معلوماتك بالفرنسية' : 'المرجو إدخال معلوماتك بالعربية'}</h3>

        {/* Conditionally set placeholder text and direction based on the selected language */}
        <input
          type="text"
          required
          placeholder={table === 'فرنسية' ? 'Nom de famille' : 'الاسم العائلي'}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          dir={table === 'فرنسية' ? 'ltr' : 'rtl'}
        />
        <input
          type="text"
          required
          placeholder={table === 'فرنسية' ? 'Prénom' : 'الاسم الشخصي'}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          dir={table === 'فرنسية' ? 'ltr' : 'rtl'}
        />
        <input
          type="number"
          required
          placeholder={table === 'فرنسية' ? 'Numéro d\'employé' : 'رقم التأجير'}
          value={n_Ddr}
          onChange={(e) => setN_Ddr(e.target.value)}
          dir={table === 'فرنسية' ? 'ltr' : 'rtl'}
        />
        <input
          type="text"
          required
          placeholder={table === 'فرنسية' ? 'Nom de l\'école' : 'اسم المدرسة'}
          value={ss_name}
          onChange={(e) => setSs_name(e.target.value)}
          dir={table === 'فرنسية' ? 'ltr' : 'rtl'}
        />
        <input
          type="text"
          required
          placeholder={table === 'فرنسية' ? 'Nom de l\'unité scolaire' : 'اسم الوحدة المدرسية'}
          value={u_Scolaire}
          onChange={(e) => setU_Scolaire(e.target.value)}
          dir={table === 'فرنسية' ? 'ltr' : 'rtl'}
        />

        <input type="submit" className="btn" value="إنشاء إستعمال الزمن" />
      </form>
    </div>
  );
};

export default ThirdForm;
