import React from 'react';
import '../components/dash/styles/First.css';

const PrivacyPolicy = () => {
  return (
      <div className="intro-section">
        <h1>سياسة الخصوصية</h1>
        <p>
          مرحبًا بكم في صفحة سياسة الخصوصية الخاصة بموقع "حقيبتي". نحن ملتزمون بحماية
          خصوصيتك وضمان حماية معلوماتك الشخصية. يرجى قراءة سياسة الخصوصية بعناية لفهم
          كيف نقوم بجمع واستخدام وحماية معلوماتك.
        </p>

        <h2>المعلومات التي نقوم بجمعها</h2>
        <p>
          نحن نجمع معلومات شخصية تقدمها لنا مباشرة مثل اسمك وعنوان بريدك الإلكتروني
          ومعلومات الاتصال الأخرى عند التسجيل في موقعنا أو عند ملء النماذج على موقعنا.
        </p>

        <h2>كيف نستخدم معلوماتك</h2>
        <p>
          نستخدم المعلومات التي نجمعها لتحسين خدماتنا وتقديم تجربة مستخدم مخصصة. قد
          نستخدم معلوماتك للاتصال بك حول تحديثات الموقع والعروض الخاصة.
        </p>

        <h2>مشاركة المعلومات</h2>
        <p>
          نحن لا نقوم ببيع أو تأجير أو تبادل معلوماتك الشخصية مع أطراف ثالثة دون
          موافقتك. قد نشارك معلوماتك مع شركاء موثوق بهم الذين يساعدوننا في تشغيل
          الموقع أو تنفيذ أعمالنا.
        </p>

        <h2>الأمان</h2>
        <p>
          نحن نتخذ تدابير معقولة لحماية معلوماتك الشخصية من الوصول غير المصرح به أو
          الاستخدام أو الكشف. نستخدم تقنيات الأمان القياسية لحماية معلوماتك.
        </p>

        <h2>تغييرات على هذه السياسة</h2>
        <p>
          قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنقوم بنشر أي تغييرات على
          هذه الصفحة، لذا يرجى مراجعتها بانتظام.
        </p>

        <p>
          إذا كان لديك أي أسئلة أو استفسارات حول سياسة الخصوصية الخاصة بنا، يرجى{' '}
          <a href="contact.php">الاتصال بنا</a>.
        </p>
      </div>
  );
};

export default PrivacyPolicy;
