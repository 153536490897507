import React, { useState } from 'react';
import FirstForm from './FirstForm';
import SecondForm from './SecondForm';
import ThirdForm from './ThirdForm';
import CryptoJS from 'crypto-js';

const FormSequence = ({id}) => {
  const [step, setStep] = useState(1); // Controls which form to display
  const [formData, setFormData] = useState({});
  let user_id = id;  // Declare user_id at a higher scope

  // Function to proceed to the next form, merging form data at each step
  const nextStep = (data) => {
    setFormData((prevData) => ({

      ...prevData,
      ...data  // Merge new data with previous data
    }));
    setStep(step + 1);
  };

  return (
    <div>
      {step === 1 && <FirstForm onSubmit={nextStep} id={user_id} />}  {/* Pass user_id as prop */}
      {step === 2 && <SecondForm onSubmit={nextStep} data={formData}/>}
      {step === 3 && <ThirdForm onSubmit={nextStep} data={formData} />}  {/* Pass the accumulated data */}
    </div>
  );
};

export default FormSequence;
