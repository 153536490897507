import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Landing.css'; // إذا كنت بحاجة إلى ملف CSS خاص بالفوتر

const HomeSection = () => {
  return (
    <section className="home">
      <div className="content">
        <h2>مرحبًا بكم في تطبيق Hakibaty</h2>
        <p>
          تطبيق Hakibaty هو منصة تعليمية مخصصة لمساعدة الأساتذة في تنظيم المهام اليومية. يقدم
          مجموعة واسعة من الأدوات التي تساعد الأساتذة على إعداد إستعمال الزمن، متابعة
          التلاميذ، إنشاء تقرير التقويم التشخيصي، وتوزيع الدروس بطريقة مرنة وسهلة. يُتيح
          التطبيق أيضًا إنشاء حسابات للطلاب وتمكينهم من الوصول إلى الجدول الزمني
          والدروس بشكل تفاعلي وسهل.
        </p>
        <Link to="/login" className="btn1 fas fa-sign-in"> الدخول</Link>
        <Link to="/register" className="btn1 fas fa-user"> إنشاء حساب</Link>
      </div>
    </section>
  );
};

export default HomeSection;
