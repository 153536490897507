import React from 'react'
import Subjects from '../Subjects'

function Hakiba() {
  return (
    <div>
        <Subjects/>
    </div>
  )
}

export default Hakiba