import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import EmploiUser from './EmploiUser';
import FormSequence from './forms/FormSequence';
import ap from '../../sevices/ap';
import { fetchUserData } from '../../sevices/apiService';
import { useNavigate } from 'react-router-dom';

const Emploi = () => {
  const [status, setStatus] = useState('loading');
  const [userId, setUserId] = useState(null);
  const [emploiInfo, setEmploiInfo] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      let decryptedId = "";
      const encryptedId = localStorage.getItem("id");

      if (encryptedId) {
        try {
          const bytes = CryptoJS.AES.decrypt(encryptedId, "your-secret-key");
          decryptedId = bytes.toString(CryptoJS.enc.Utf8);
          setUserId(decryptedId);
        } catch (error) {
          console.error("Decryption error:", error);
          toast.error("خطأ في فك التشفير");
          setStatus('failure');
          return;
        }
      } else {
        toast.error("لم يتم العثور على معرف المستخدم المشفر");
        setStatus('failure');
        return;
      }

      try {
        // Fetch user info
        const userInfoResult = await ap.fetchUserInfo(decryptedId);
        const emploiInfoData = userInfoResult?.emploiInfo;

        // Fetch user data
        const userDataResult = await fetchUserData(decryptedId); // Adjust to actual function
        setUserData(userDataResult); // Assuming userDataResult contains relevant user data
        if (userInfoResult.status === 'success' && emploiInfoData) {
          setEmploiInfo(emploiInfoData);
          setStatus('success');
        } else {
          setStatus('failure');
        }
      } catch (error) {
        toast.error("حدث خطأ أثناء جلب البيانات");
        setStatus('failure');
      }
    };

    fetchData();
  }, []);

  // Navigate based on status
  useEffect(() => {
    if (status === 'failure') {
      navigate('/emploi'); // Navigate to /emploi
    } else if (status === 'success') {
      navigate('/dashboard/emploi'); // Navigate to /dashboard/emploi
    }
  }, [status, navigate]);

  return (
    <div>
      {status === 'loading' && (
        <p className="loading-message">... جاري تحميل البيانات </p>
      )}
      {status === 'success' && emploiInfo && userData && (
        <EmploiUser emploiInfo={emploiInfo} userData={userData} />
      )}
      {status === 'failure' && <FormSequence id={userId} />}
    </div>
  );
};

export default Emploi;
