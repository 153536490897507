import React, { useState, useEffect } from "react";
import './styles/FormPage.css';
import { toast } from 'react-toastify';

const FirstForm = ({ onSubmit, id }) => {  // Corrected destructuring
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [selectedStatut, setSelectedStatut] = useState("");
  const [levels, setLevels] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedDomaine, setSelectedDomaine] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);


  const regions = [
    "طنجة-تطوان-الحسيمة",
    "الشرق",
    "فاس-مكناس",
    "الرباط-سلا-القنيطرة",
    "بني ملال-خنيفرة",
    "الدار البيضاء-سطات",
    "مراكش-آسفي",
    "درعة-تافيلالت",
    "سوس-ماسة",
    "كلميم-واد نون",
    "العيون-الساقية الحمراء",
    "الداخلة-وادي الذهب"
  ];

  const provincesData = {
    "طنجة-تطوان-الحسيمة": [
      "طنجة-أصيلة",
      "المضيق-الفنيدق",
      "تطوان",
      "فحص-أنجرة",
      "العرائش",
      "الحسيمة",
      "شفشاون",
      "وزان"
    ],
    "الشرق": [
      "وجدة-أنجاد",
      "الناظور",
      "بركان",
      "الدريوش",
      "تاوريرت",
      "جرادة",
      "فجيج",
      "جرسيف"
    ],
    "فاس-مكناس": [
      "فاس",
      "مكناس",
      "الحاجب",
      "إفران",
      "مولاي يعقوب",
      "صفرو",
      "بولمان",
      "تاونات",
      "تازة"
    ],
    "الرباط-سلا-القنيطرة": [
      "الرباط",
      "سلا",
      "الصخيرات-تمارة",
      "القنيطرة",
      "الخميسات",
      "سيدي قاسم",
      "سيدي سليمان"
    ],
    "بني ملال-خنيفرة": [
      "بني ملال",
      "أزيلال",
      "الفقيه بن صالح",
      "خنيفرة",
      "خريبكة"
    ],
    "الدار البيضاء-سطات": [
      "الدار البيضاء",
      "المحمدية",
      "الجديدة",
      "النواصر",
      "مديونة",
      "بن سليمان",
      "برشيد",
      "سطات",
      "سيدي بنور"
    ],
    "مراكش-آسفي": [
      "مراكش",
      "شيشاوة",
      "الحوز",
      "قلعة السراغنة",
      "الصويرة",
      "الرحامنة",
      "آسفي",
      "اليوسفية"
    ],
    "درعة-تافيلالت": [
      "الرشيدية",
      "ورزازات",
      "ميدلت",
      "تنغير",
      "زاكورة"
    ],
    "سوس-ماسة": [
      "أكادير إداوتنان",
      "إنزكان-آيت ملول",
      "اشتوكة آيت باها",
      "تارودانت",
      "تيزنيت",
      "طاطا"
    ],
    "كلميم-واد نون": [
      "كلميم",
      "آسا-زاك",
      "طانطان",
      "سيدي إفني"
    ],
    "العيون-الساقية الحمراء": [
      "العيون",
      "بوجدور",
      "طرفاية",
      "السمارة"
    ],
    "الداخلة-وادي الذهب": [
      "وادي الذهب",
      "أوسرد"
    ]
  };

  const statuts = ["عربية", "فرنسية", "مزدوج"];
  const levelsData = {
    "عربية": ["1 ابتدائي", "2 ابتدائي", "3 ابتدائي", "4 ابتدائي", "5 ابتدائي", "6 ابتدائي",'1+2 ابتدائي', '3+4 ابتدائي', '5+6 ابتدائي', '1+2+3 ابتدائي', '4+5+6 ابتدائي', '3+4+5+6 ابتدائي'],
    "فرنسية": ["1 ابتدائي", "2 ابتدائي", "3 ابتدائي", "4 ابتدائي", "5 ابتدائي", "6 ابتدائي",'1+2 ابتدائي', '3+4 ابتدائي', '5+6 ابتدائي', '1+2+3 ابتدائي', '4+5+6 ابتدائي', '3+4+5+6 ابتدائي'],
    "مزدوج": ["1 ابتدائي", "2 ابتدائي", "3 ابتدائي", "4 ابتدائي", "5 ابتدائي", "6 ابتدائي",'1+2 ابتدائي', '3+4 ابتدائي', '5+6 ابتدائي', '1+2+3 ابتدائي', '4+5+6 ابتدائي', '3+4+5+6 ابتدائي'],
  };

  const domaine = ["مدرسة في المجال القروي", "مدرسة في المجال الحضري"];

  useEffect(() => {
    if (selectedRegion) {
      setProvinces(provincesData[selectedRegion] || []);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedStatut) {
      setLevels(levelsData[selectedStatut] || []);
    }
  }, [selectedStatut]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form fields
    if (!selectedRegion || !selectedProvince || !selectedStatut || !selectedLevel || !selectedDomaine) {
      toast.error("يرجى ملء جميع الحقول");
      return;
    }

    setIsSubmitting(true);

    const data = {
      id: id,  // Using the id passed as a prop
      region: selectedRegion,
      province: selectedProvince,
      statut: selectedStatut,
      level: selectedLevel,
      domaine: selectedDomaine
    };

    try {
        onSubmit(data);  // Move to the next step
        toast.success("المرجو إدخال معلوماتك بعناية");
    } catch (error) {
         if (error.request) {
        toast.error("لا يوجد استجابة من الخادم، يرجى المحاولة لاحقاً");
      } else {
        toast.error("خطأ غير متوقع: " + error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNext = () => {
    if (currentStep === 1 && !selectedRegion) {
      toast.error("يرجى اختيار الأكاديمية الجهوية");
      return;
    }
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="account-form1">
      <form className="register-form active" onSubmit={handleSubmit}>
        <h3>تفاصيل مقر العمل</h3>
        
        <select value={selectedRegion} onChange={(e) => setSelectedRegion(e.target.value)}>
          <option value="">الأكاديمية الجهوية</option>
          {regions.map((region, index) => (
            <option key={index} value={region}>
              {region}
            </option>
          ))}
        </select>

        <select value={selectedProvince} onChange={(e) => setSelectedProvince(e.target.value)}>
          <option value="">المديرية الإقليمية</option>
          {provinces.map((province, index) => (
            <option key={index} value={province}>
              {province}
            </option>
          ))}
        </select>

        <select value={selectedStatut} onChange={(e) => setSelectedStatut(e.target.value)}>
          <option value="">القسم المسند إليك</option>
          {statuts.map((statut, index) => (
            <option key={index} value={statut}>
              {statut}
            </option>
          ))}
        </select>

        <select value={selectedLevel} onChange={(e) => setSelectedLevel(e.target.value)}>
          <option value="">المستوى المسند إليك</option>
          {levels.map((level, index) => (
            <option key={index} value={level}>
              {level}
            </option>
          ))}
        </select>

        <select value={selectedDomaine} onChange={(e) => setSelectedDomaine(e.target.value)}>
          <option value="">نطاق المؤسسة</option>
          {domaine.map((domaineOption, index) => (
            <option key={index} value={domaineOption}>
              {domaineOption}
            </option>
          ))}
        </select>

        <button type="submit" className="btn" disabled={isSubmitting}>
          {isSubmitting ? "جاري الإرسال..." : "متابعة"}
        </button>
      </form>
    </div>
  );
};

export default FirstForm;
