import React from 'react'
import { Outlet } from 'react-router-dom'
import "../styles/HomeLayout.css"
import { ToastContainer, toast } from 'react-toastify';

const HomeLayout = () => {
  return (
    <>
    <Outlet />
            <ToastContainer position='top-center' />
            </>
  )
}

export default HomeLayout