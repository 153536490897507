import React from 'react';
import aboutImage from '../assets/images/logos/about-image.jpg';
import icon1 from '../assets/images/logos/sub1.png';
import icon2 from '../assets/images/logos/sub2.png';
import icon3 from '../assets/images/logos/sub3.png';
import '../styles/Landing.css'; // إذا كنت بحاجة إلى ملف CSS خاص بالفوتر

const AboutSection = () => {
  return (
    <section className="about">
      <div className="images">
        <img src={aboutImage} alt="About Image" />
      </div>
      <div className="content">
        <h1 className="about-title">من نحن</h1>
        <p>مرحبًا بكم في "حقيبتي"، منصة التعليم الإلكترونية المبتكرة التي صُممت خصيصًا لدعم الأساتذة في تنظيم وتحضير الدروس والوثائق بطريقة مهنية وسهلة. نحن نؤمن بأهمية التعليم الجيد ونسعى جاهدين لتقديم أدوات تساعد الأساتذة على تحقيق أقصى استفادة من وقتهم ومواردهم.</p>
        <p>تأسس موقع "حقيبتي" كمشروع يهدف إلى تبسيط عملية إعداد المواد التعليمية وتوفير أدوات فعّالة لتحسين تجربة التدريس. نحن نقدم مجموعة متنوعة من الحلول التي تشمل إعداد الجداول الدراسية، وتوفير تقارير تقييمية شاملة، وإدارة المذكرات اليومية، وكل ذلك في بيئة رقمية سهلة الاستخدام.</p>
        <p>فريقنا يتكون من مجموعة من المحترفين المتخصصين في مجالات التعليم والتكنولوجيا، والذين يعملون بجد لضمان تقديم أفضل تجربة للمستخدمين. نحن ملتزمون بتطوير أدواتنا باستمرار استجابةً لاحتياجات الأساتذة والتغيرات في المجال التعليمي.</p>
        <p>انضم إلينا اليوم واستفد من خدماتنا المصممة لتعزيز جودة التعليم وتحقيق النجاح في مهامك التعليمية. نحن هنا لدعمك في كل خطوة على الطريق وتقديم الحلول التي تحتاجها.</p>
        <a href="courses.php" className="btn">استكشاف خدماتنا</a>
        
        <div className="icons-container">
          <div className="icons">
            <img src={icon1} alt="Icon 1" />
            <h3>الإبداع</h3>
            <span>نحن ملتزمون بالابتكار وتقديم حلول جديدة في كل مرحلة من مراحل التعليم.</span>
          </div>
          <div className="icons">
            <img src={icon2} alt="Icon 2" />
            <h3>الدعم</h3>
            <span>نوفر دعمًا مخصصًا ومستمرًا لمساعدتك في استخدام أدواتنا بشكل فعّال.</span>
          </div>
          <div className="icons">
            <img src={icon3} alt="Icon 3" />
            <h3>التميز</h3>
            <span>نسعى لتحقيق أعلى مستويات الجودة في كل خدمة نقدمها ونتطلع إلى تحقيق التميز المستدام.</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
