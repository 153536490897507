import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomeLayout from "./page/HomeLayout";
import Landing from "./page/Landing";
import Register from "./page/Register";
import Login from "./page/Login";
import Dashboard from "./page/Dashboard";
import Logout from "./page/Logout";
import Emploi from "./components/emploi/Emploi";
import First from "./components/dash/First";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AboutSection from "./components/AboutSection";
import Contact from "./components/Contact";
import FAQ from "./components/Faq";
import HelpCenter from "./components/HelpCenter";
import Hakiba from "./components/dash/Hakiba";
import Eval from "./components/dash/Eval";
import DailyReminder from "./components/dash/DailyReminder";
import Subjects from "./components/Subjects";
import FeedbackForm from "./components/Feedback";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import ConvertToWord from "./components/emploi/ConvertToWord";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      { index: true, element: <Landing />},
      { path: "about", element: <AboutSection />  },
      { path: "subjects", element: <Subjects /> },
      { path: "contact", element: <Contact /> },
      { path: "emploi", element: <Emploi /> },
      { path: "faq", element: <FAQ /> },
      { path: "helpcenter", element: <HelpCenter /> },
      { path: "register", element: <Register /> },
      { path: "login", element: <Login /> },
      { path: "feedback", element: <FeedbackForm /> },
      { path: "privacy", element: <PrivacyPolicy /> },
      { path: "terms", element: <TermsOfUse /> },
      {
        path: "dashboard",
        element: <Dashboard />,
        children: [
          { index: true, element: <First /> },
          { path: "emploi", element: <Emploi /> },
          { path: "hakiba", element: <Hakiba /> },
          { path: "doc", element: <ConvertToWord /> },
          { path: "eval", element: <Eval /> },
          { path: "calendar", element: <DailyReminder /> },
          { path: "contact", element: <Contact /> }

        ]
      },
      { path: "logout", element: <Logout /> },
    ]
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer position='top-center' />
    </>
  );
}

export default App;
