import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sub1 from "../assets/images/logos/sub1.png";
import sub2 from "../assets/images/logos/sub2.png";
import sub3 from "../assets/images/logos/sub3.png";
import sub4 from "../assets/images/logos/sub4.png";
import sub5 from "../assets/images/logos/sub5.png";
import sub6 from "../assets/images/logos/sub6.png";
import '../styles/Landing.css'; // Assuming you want to style it separately


const Subjects = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to handle the click event for إستعمال الزمن
  const handleNavigation = (path) => {
    if (location.pathname === "/dashboard/hakiba") {
      navigate(path);
    }
  };

  // Function to show toast for other services
  const handleOtherServicesClick = () => {
    if (location.pathname === "/dashboard/hakiba") {
      toast.info("قريبا سيتم إطلاق هذه الخدمة", {
        style: { direction: 'rtl', textAlign: 'center' }, // RTL style for the toast
      });
    }
  };

  return (

    <section className="subjects">
      <h1 className="heading">خدماتنا</h1>
      <div className="box-container">
        <div className="box" onClick={() => handleNavigation('/dashboard/emploi')}>
          <img src={sub1} alt="إستعمال الزمن" />
          <h3>إستعمال الزمن</h3>
          <p>إعداد الجدول الزمني بسهولة ومرونة باستخدام أدوات التخطيط الذكية.</p>
        </div>

        <div className="box" onClick={handleOtherServicesClick}>
          <img src={sub2} alt="تقرير التقويم التشخيصي" />
          <h3>تقرير التقويم التشخيصي</h3>
          <p>تقارير شاملة للتقويم التشخيصي بمساعدة الذكاء الاصطناعي.</p>
        </div>

        <div className="box" onClick={handleOtherServicesClick}>
          <img src={sub3} alt="المذكرة اليومية" />
          <h3>المذكرة اليومية</h3>
          <p>إدارة مذكرتك اليومية والوصول إليها من أي مكان.</p>
        </div>

        <div className="box" onClick={handleOtherServicesClick}>
          <img src={sub4} alt="التوزيع والجذاذات" />
          <h3>التوزيع والجذاذات</h3>
          <p>تنظيم وتخزين جميع الجذاذات والدروس والتمارين.</p>
        </div>

        <div className="box" onClick={handleOtherServicesClick}>
          <img src={sub5} alt="الدروس والتمارين" />
          <h3>الدروس والتمارين</h3>
          <p>الوصول إلى محتوى تعليمي شامل ومتنوع.</p>
        </div>

        <div className="box" onClick={handleOtherServicesClick}>
          <img src={sub6} alt="خدمات أخرى" />
          <h3>خدمات أخرى</h3>
          <p>استفد من مجموعة واسعة من الخدمات التعليمية الإضافية.</p>
        </div>
      </div>
    </section>

  );
};

export default Subjects;
