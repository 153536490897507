import React from 'react';
import './styles/First.css'; // Assuming the CSS file is named First.css

function First() {
  return (
    <div className="intro-section">
      <h1>مرحباً بكم في تطبيق "حقيبتي"</h1>
      <p>
        نحن بصدد تطوير تطبيق يهدف إلى تسهيل وتحسين عمل أساتذة التعليم الابتدائي. 
        من خلال هذا التطبيق، ستتمكنون من الوصول إلى مجموعة واسعة من الأدوات الذكية والخدمات 
        التي ستساعدكم على تنظيم وقتكم وتحضير الدروس بكفاءة وفعالية.
      </p>
      <p>
        يركز تطبيق "حقيبتي" على تلبية احتياجات الأساتذة من خلال توفير خدمات متنوعة تشمل:
      </p>
      <ul>
        <li>إعداد جداول زمنية مخصصة.</li>
        <li>إنشاء وتحليل تقارير التقويم التشخيصي بمساعدة الذكاء الاصطناعي.</li>
        <li>تنظيم المذكرة اليومية والوصول إليها من أي مكان.</li>
        <li>إدارة التوزيعات الدراسية والجذاذات والتمارين.</li>
      </ul>
      <p>
        التطبيق في مرحلته الأولى، ولكننا نعمل بجد لتطوير مزيد من الخدمات التي ستسهل عملية 
        التعليم والتخطيط. نرحب بأي ملاحظات أو اقتراحات منكم لضمان أن هذا التطبيق يلبي 
        احتياجاتكم التعليمية بشكل مثالي.
      </p>
      <p>تابعونا للحصول على آخر التحديثات والتطويرات.</p>
    </div>
  );
}

export default First;
